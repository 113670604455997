$(document).ready(function () {

    /////////////
    // SLIDERS
    
    // Slider BANNER PRIM
    $('.site-home .slider-big .slider').slick({
        dots: true,
        slidesToShow: 1,
        arrows: false,
        infinite: true,
        speed: 600,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 751,
                settings: {
                    dots: false,
                    arrows: true,
                    adaptiveHeight: true,
                }
            },
        ]
    });
    $('.site-home .slider-big .slick-dots > li').each( function() {
        let val = $(this).find('button').text()
        if(val < 10){
            $(this).find('button').text('0'+val);
        }
    });

    $('#slider-mini .slider').slick({
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        initialSlide: 1,
        fade: true,
    });

    $('#home-products-block .slider').slick({
        dots: true,
        infinite: false,
        arrows: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    // dots: true
                }
            },
            {
                breakpoint: 751,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // dots: true
                }
            },
        ]
    });


    // si se mueve el slider big, movemos el mini a la siguiente posición: big + 1
    $('#slider-big .slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('#slider-mini .slider').slick('slickGoTo', nextSlide + 1);
    });

    // si se mueve el slider mini, movemos el big a la anterior posición: mini - 1
    $('#slider-mini .slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('#slider-big .slider').slick('slickGoTo', nextSlide - 1);
    });
});